import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';

import {AddedFileToQueueModalComponent} from './components/added-file-to-queue-modal.component';
import {AddedFileToQueueModalService} from './services/added-file-to-queue-modal.service';

@NgModule({
  declarations: [AddedFileToQueueModalComponent],
  imports: [
    CommonModule,
    TranslocoModule,
  ],
  providers: [AddedFileToQueueModalService],
})
export class AddedFileToQueueModalModule {
}
