import {Directive, HostListener, Input} from '@angular/core';
import {takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';
import {MediaUploadStorageUrls} from '@models/cloud-media-uploader';
import {BoatDetailsService} from '@services/boat-details/boat-details.service';

import {DownloadFileParams, DownloadUrl} from '../models/download-files.model';
import {FileDownloaderService} from '../services/file-downloader.service';

@Directive({
  selector: '[appCloudFileDownloader]',
})
export class CloudFileDownloaderDirective extends DestroySubscription {

  @Input() downloadUrl: DownloadUrl;
  @Input() filter: DownloadFileParams = {};
  @Input() fileName: string;
  @Input() s3FileName: string;
  @Input() boatFile = false;
  @Input() alwaysDownload = false;
  @Input() uploadKey: MediaUploadStorageUrls;
  @Input() boatId: number;

  get filterParams(): DownloadFileParams {
    if (this.boatFile) {
      const boatId = this.boatId || this.boatDetailsService.boatId;
      if (boatId) {
        return {...this.filter, boatId};
      }
      return this.filter;
    }
    return this.filter;
  }

  constructor(
    private fileDownloader: FileDownloaderService,
    private boatDetailsService: BoatDetailsService,
  ) {
    super();
  }

  @HostListener('click', ['$event']) download(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    const downloadUrl = this.downloadUrl;
    if (downloadUrl) {
      this.downloadCloudFile(downloadUrl, this.filterParams, this.fileName);
      return;
    }
    const uploadKey = this.uploadKey;
    if (uploadKey) {
      this.downloadCloudFileByUploadKey(this.uploadKey, this.fileName, this.s3FileName);
    }
  }

  private downloadCloudFile(url: DownloadUrl, payload: DownloadFileParams, name: string): void {
    this.fileDownloader.downloadCloudFile(url, payload, name, this.alwaysDownload).pipe(
      takeUntil(this.destroyStream$),
    ).subscribe();
  }

  private downloadCloudFileByUploadKey(uploadKey: MediaUploadStorageUrls, name: string, s3FileName: string): void {
    this.fileDownloader.downloadCloudFileByUploadKey(uploadKey, s3FileName, name, this.alwaysDownload).pipe(
      takeUntil(this.destroyStream$),
    ).subscribe();
  }

}
